import { KeycloakService } from 'keycloak-angular';

const clientId = 'propup-plattform';
const issuer = 'https://auth.propup.at/auth/realms/PROPUP';
const initializeKeycloak = (keycloakService: KeycloakService) => {
  return () =>
    keycloakService.init({
      config: {
        url: 'https://auth.propup.at/auth',
        realm: 'PROPUP',
        clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false,
      },
      bearerExcludedUrls: ['/assets'],
    });
};

export const keycloak = {
  clientId,
  issuer,
  initializeKeycloak,
};
